.textarea {
  height: 253px;
  padding: 12px;
  border: 1px solid #cdd4d8;
  border-radius: 4px;
  color: #141e49;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.label {
  color: #141e49;
  font-size: 16px;
  font-weight: normal;
}

.optionalSpan {
  color: rgb(182, 186, 198);
  font-size: 14px;
}

.md {
  height: 130px;
}

.textarea::placeholder {
  color: #666c88;
}

.noResize {
  resize: none;
}

.fullWidth {
  width: 100%;
}
