.root {
  display: flex;
  height: 100%;
  min-height: 100dvh;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f8f8f8;

  @media (max-width: 1024px) {
    background-color: #fff;
  }
}

.content {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1248px;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 5rem;
  padding-block: 1rem;
  padding-inline: 1.5rem;

  @media (max-width: 1024px) {
    padding-block: 2rem;
  }

  @media (max-width: 450px) {
    padding-top: 1rem;
    padding-bottom: 3rem;
    padding-inline: 0.75rem;
  }
}

.singleChild {
  justify-content: center;
}
