.root {
  display: flex;
  max-width: 600px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.image {
  margin-bottom: 19px;
}
