.root {
  display: flex;
  width: 100%;
  max-width: 1248px;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  margin: 0 auto;
  color: var(--color-text-primary);
  font-size: 14px;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1rem;
  }
}
