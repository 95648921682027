.root {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.wrapper {
  display: flex;
  width: 100%;
  max-width: 1248px;
  justify-content: space-between;
  margin: 0 auto;
}

.logoContainer {
  padding-left: 2rem;
  line-height: 0;
  padding-block: 8px;
}
