html,
body {
  padding: 0;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family:
    Poppins,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  padding: 0;
  margin: 0;
}

.relative {
  position: relative;
}

:root {
  --candidate-background: #fff;
  --candidate-font: "Poppins", sans-serif;
  --candidate-primary-color: rgba(15, 127, 159, 1);
  --candidate-button-primary-text-color: #fff;
  --candidate-button-secondary-text-color: #666c88;
  --candidate-icon-color: #46bc81;
  --kf-color-text-primary: rgba(69, 73, 95, 1);
  --color-text-primary: rgba(102, 108, 136, 1);
}
