.formControl {
  padding-right: 10px;
  margin-left: 0 !important;
  background: rgba(196, 205, 215, 0.15);
  opacity: 0;
}

.label {
  font-size: 16px !important;
  font-weight: 500 !important;
}
