.root {
  display: flex;
  padding: 0;
  margin: 0;
  font-weight: 500;
  gap: 2rem;
  list-style: none;

  @media (max-width: 450px) {
    gap: 1rem;
  }
}
