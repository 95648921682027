.root {
  display: flex;
  max-width: max-content;
  align-items: center;
  padding: 4px 6px 4px 4px;
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;
  gap: 12px;
  margin-block: 4px;
  transition: all 0.3s;
}

.root:hover {
  background-color: #e7fafb;
}

.activeRoot {
  background-color: #e7fafb;
}

.avatar {
  width: 24px;
  height: 24px;
  background-color: #f8f8f8 !important;
  color: #666c88 !important;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.truncatedText {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu {
  width: 280px;
  margin-top: 6px;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  gap: 8px;
}
