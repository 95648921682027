.root {
  display: flex;
  gap: 1rem;
}

.spaceBetween {
  justify-content: space-between;
}

.verticalMargin {
  margin: 50px 0 30px;
}

.vertical {
  flex-direction: column;
}

.center {
  align-items: center;
}

.gapNone {
  gap: 0;
}

.gapSm {
  gap: 0.25rem;
}

.gapMd {
  gap: 0.75rem;
}

.gapLg {
  gap: 1.5rem;
}

.gapXl {
  gap: 3rem;
}